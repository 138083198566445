$smartphones: 993px;
$hidpi: 1440px;

body {
	background-color: #000;
	font-family: 'Inconsolata', monospace;
	color: #fff;
	padding: 0;
	margin: 0;
}

a {
	color: #fff;
}

::-webkit-scrollbar-track{
	box-shadow: none;
}
::-webkit-scrollbar {
	width: 2px;
	height: 2px;
	border-radius: 1px;
}
::-webkit-scrollbar-thumb {
	background-color: #3564a4;
}

.cmd {
	width: 100svw;
	height: 100svh;
	padding: 0;
	margin: 0;
	overflow: hidden;

	.cmd__input__wrapper {
		position: fixed;
		width: 100svw;
		height: 100svh;

		.init__message {

			.start {
				color: #ffab00;
			}

			.info {
				color: #01DD07;
				font-size: 14px;
				margin-top: 10px;
				margin-bottom: 20px;
			}

			.get__help {
				cursor: pointer;
				border-bottom: 1px dotted #01DD07;
			}
		}

		.cmd__input {
			position: absolute;
			top: -1000px;
		}

		.cmd__view__history {
			overflow: hidden;
			overflow-y: scroll;
			position: relative;
			max-height: 88svh;
			padding-bottom: 40px;
			margin-left: 10px;
			margin-top: 10px;
			margin-bottom: 20px;

			&.half {
				@media (max-width: $smartphones) {
					max-height: 40vh;
				}
			}

			p {
				padding: 0;
				margin: 0;
			}

			.slow {
				animation: show 0.2s forwards;
			}

			.slow.second {
				animation: showsecond 0.4s forwards;
			}

			.slow.third {
				animation: showsecond 0.7s forwards;
			}
			.slow.fourth {
				animation: showsecond 0.9s forwards;
			}

			@keyframes show {
				0% {
					opacity: 0;
				}
				50% {
					opacity: 0.5;
				}
				100% {
					opacity: 1;
				}
			}

			@keyframes showsecond {
				0% {
					opacity: 0;
				}
				50% {
					opacity: 0;
				}
				75% {
					opacity: 0.5;
				}
				100% {
					opacity: 1;
				}
			}

			.info {
				width: 100%;
				overflow: hidden;
				white-space: nowrap;
				margin-top: 20px;

				.title {
					margin-right: 10px;
					cursor: pointer;
					text-decoration: underline;
				}

				.lines {
					margin-left: 10px;
					margin-right: 10px;
					color: #ffab00;
				}
			}

			.subinfo {
				ul {
					li {
						color: #9e9e9e;
						list-style-type: none;

						span,a {
							color: #fff;
							padding-left: 5px;
							padding-right: 5px;
						}

						a {
							cursor: pointer;
							text-decoration: underline;
						}
					}
				}

				ul.social-links {
					padding-inline-start: 10px;

					li {
						line-height: 25px;

						a {
							padding-left: 0;
							@media (max-width: $smartphones) {
								font-size: 14px;
							}
						}

						.social-img {
							width: 20px;
							vertical-align: middle;

							@media (max-width: $smartphones) {
								width: 15px;
							}
						}
					}
				}

				p,a {
					margin-left: 10px;
				}
			}

			.works {

				.work {
					margin-top: 10px;

					.url {
						color: #3564a4;
					}

					.tags {

						i {
							color: #ffab00;
							opacity: 0.8;
							margin-left: 5px;
						}
					}
				}
			}

			.cmd__usr {
				display: inline-block;
			}
		}

		.cmd__usr__row {
			margin-bottom: 10px;

			&:first-child {
				margin-bottom: 7px;
			}
		}

		.cmd__visible__input {
			transition: all 0.2s ease;
			position: fixed;
			bottom: 10px;
			left: 10px;
			color: #fff;

			p {
				margin: 0;
				padding: 0;
			}

			@keyframes blink {
				0% {
					opacity: 1;
				}
				50% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			&:after {
				position: absolute;
				top: -2.5px;
				right: -10px;
				content: " ";
				height: 22px;
				border-left: 7px solid #fff;
				animation: blink 0.9s infinite;
			}
		}

		.cmd__usr {
			margin-top: 2px;
			background-color: #3564a4;
			padding: 3px 16px;
			margin-right: 16px;
			position: relative;

			&:before {
				position: absolute;
				top: 2px;
				left: -9px;
				content: " ";
				height: 1px;
				border-bottom: 17px solid #000;
				border-left: 17px solid transparent;
				transform: rotate(-45deg);
			}

			&:after {
				position: absolute;
				top: 2px;
				right: -8px;
				content: " ";
				height: 1px;
				border-bottom: 17px solid #3564a4;
				border-left: 17px solid transparent;
				transform: rotate(-45deg);
			}
		}
	}
}
